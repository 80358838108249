import { faBath } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faBaby } from "@fortawesome/free-solid-svg-icons";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { faBicycle } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { faCaravan } from "@fortawesome/free-solid-svg-icons";
import { faCarrot } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faTemperatureLow } from "@fortawesome/free-solid-svg-icons";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faDirections } from "@fortawesome/free-solid-svg-icons";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { faHammer } from "@fortawesome/free-solid-svg-icons";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faTemperatureHigh } from "@fortawesome/free-solid-svg-icons";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faLemon } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faPepperHot } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faTree } from "@fortawesome/free-solid-svg-icons";
import { faScrewdriver } from "@fortawesome/free-solid-svg-icons";
import { faSeedling } from "@fortawesome/free-solid-svg-icons";
import { faSuitcase } from "@fortawesome/free-solid-svg-icons";
import { faWrench } from "@fortawesome/free-solid-svg-icons";

import France from "../assets/images/fr.svg";
import UnitedKingdom from "../assets/images/uk.svg";
import Netherlands from "../assets/images/nl.svg";

function icon(name) {
    switch(name) {
        case "bathtub":
            return faBath;
        case "bed":
            return faBed;
        case "baby":
            return faBaby;
        case "backArrow":
            return faLongArrowAltLeft;
        case "bicycle":
            return faBicycle;
        case "calendar":
            return faCalendarDay;
        case "camera":
            return faCamera;
        case "caravan":
            return faCaravan;
        case "carrot":
            return faCarrot;
        case "check":
            return faCheck;
        case "cart":
            return faShoppingCart;
        case "cold":
            return faTemperatureLow;
        case "coffee":
            return faCoffee;
        case "directions":
            return faDirections;
        case "door":
            return faDoorOpen;
        case "euro":
            return faEuroSign;
        case "hammer":
            return faHammer;
        case "info":
            return faInfo;
        case "home":
            return faHome;
        case "hot":
            return faTemperatureHigh;
        case "leaf":
            return faLeaf;
        case "list":
            return faList;
        case "lemon":
            return faLemon;
        case "people":
            return faUsers;
        case "pepper":
            return faPepperHot;
        case "phone":
            return faPhone;
        case "pin":
            return faMapPin;
        case "star":
            return faStar;
        case "tree":
            return faTree;
        case "screwdriver":
            return faScrewdriver;
        case "seedling":
            return faSeedling;
        case "suitcase":
            return faSuitcase;
        case "wrench":
            return faWrench;
        default:
            return null;
    }
}

function image(image, type) {
    
    if(image === undefined) {
        return null;
    }

    switch(type) {
        case "xsmall":
            return image.formats?.xsmall?.url ?? image.formats?.xsmall?.url ?? image.url;
        case "small":
            return image.formats?.small?.url ?? image.url;
        case "medium":
            return image.formats?.medium?.url ?? image.formats?.small?.url ?? image.url;
        case "large":
            return image.formats?.large?.url ?? image.formats?.medium?.url ?? image.url;
        case "xlarge":
            return image.formats?.xlarge?.url ?? image.formats?.large?.url ?? image.url;
        default:
            return image.url;
    }
}

function currentLanguage(language) {

    switch(language) {
        default:
        case 'fr':
            return { span: 'FR', src: France, alt: 'French' };
        case 'en':
            return { span: 'EN', src: UnitedKingdom, alt: 'English' };
        case 'nl':
            return { span: 'NL', src: Netherlands, alt: 'Dutch' };
    }
}

const utils = {
    icon,
    image,
    currentLanguage
};

export default utils;
