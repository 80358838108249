import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faBalanceScale } from "@fortawesome/free-solid-svg-icons";

const query = graphql`query FooterItemsQuery {
    allStrapiFooterItems (sort: {fields: order}) {
        edges {
            node {
                name
                locale
                order
                path
                enabled
            }
        }
    }
}`;

const Footer = (props) => {
    
    const locale = props.locale;

    return (
        <footer id="footer">
            <div className="inner pt-3">
                <StaticQuery query={query} render={ data => (
                    <nav className="d-flex justify-content-center navbar-light bg-light mb-4">
                        <ul className="navbar-nav flex-row border-1">
                            {
                                data.allStrapiFooterItems?.edges?.filter(x => !x.node.is_special && x.node.locale === locale).map((item, index) => (
                                <li key={index} className="nav-item mx-3 ps-0">
                                    <Link className="nav-link dark border-0" key={index} to={item.node.path}>{item.node.name}</Link>
                                </li> ))
                            }
                        </ul>
                    </nav>
                )} />
                <ul className="copyright">
                    <li>&copy; Idunn - 2022</li><li>Design: <a href="https://www.testingworks.net" target="_blank" rel="noopener noreferrer">Clément Joye</a><a href="https://html5up.net" target="_blank" rel="noopener noreferrer">/ HTML5 UP</a></li>
                    <li><FontAwesomeIcon className="me-2" icon={faMapPin} size="xs"/>Idunn - 8 Rue Du Sy, 50480 Amfreville, France</li>
                    <li><FontAwesomeIcon className="me-2" icon={faEnvelope} size="xs"/>Email: idunn.annekris@gmail.com</li>
                    <li><FontAwesomeIcon className="me-2" icon={faBalanceScale} size="xs"/><a href="/mentions-legales">Mentions légales / Legal notes</a></li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer
