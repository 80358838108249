import React from "react";
import { Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import { graphql, Link, StaticQuery } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const query = graphql`query MenuItemsQuery {
    allStrapiHeaderItems (sort: {fields: order}) {
        edges {
            node {
                name
                locale
                order
                path
                icon
                is_child
                is_special
                header_items {
                    name
                    order
                    path
                    icon
                    is_child
                    enabled
                    is_special
                }
            }
        }
    }
}`;

const ExactNavLink = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [iconState, setIconState] = React.useState(faChevronUp);
  const toggle = () => { 

      if(isOpen) {
          setIconState(faChevronUp);
      }
      else {
          setIconState(faChevronDown);
      }

      setIsOpen(!isOpen);

  };

  const CollapsableOnKeyDown = (e) => { if (e.keyCode === 38) { toggle() }} 

  if(props.children !== undefined && props.children.length > 0) {
    return (
      <>
        <li>
          <Link className="d-inline-block me-2" onClick={props.onToggleMenu} to={props.path}>{props.name}</Link>
          <FontAwesomeIcon icon={iconState} size="xs" onClick={toggle} onKeyDown={CollapsableOnKeyDown}/>
        </li>
        <Collapse in={isOpen}>
          <ul className="ps-2 mt-2">
          {
            props.children.sort((a, b) => { return a.order - b.order }).map((child, index) => (
              <li key={index}><Link className="alt" onClick={props.onToggleMenu} to={child.path}>{child.name}</Link></li>
            ))
          }
          </ul>
        </Collapse>
      </>
      
    )
  }
  else {
    return (
      <li><Link onClick={props.onToggleMenu} to={props.path}>{props.name}</Link></li>
    )
  }
}

const Menu = (props) => {

  const locale = props.locale;

    return (
        <StaticQuery
            query={query}
            render=
            {
                data => ( 
                    <nav id="menu">
                        <div className="inner" style={{overflow: "hidden"}}>
                            <ul className="links">
                            {
                              data.allStrapiHeaderItems?.edges?.filter(x => !x.node.is_child && !x.node.is_special && x.node.locale === locale).map((item, index) => (
                              <ExactNavLink key={index} name={item.node.name} path={item.node.path} children={item.node.header_items} onToggleMenu={props.onToggleMenu} />
                            ))
                            }
                            </ul>
                            <ul className="actions vertical">
                                {
                                  data.allStrapiHeaderItems?.edges?.filter(x => !x.node.is_child && x.node.is_special && x.node.locale === locale).map((item, index) => (
                                      <li key={index}><Link onClick={props.onToggleMenu} to={item.node.path} className="button special fit">{item.node.name}</Link></li>
                                  ))
                                }
                            </ul>
                        </div>
                        <a role="button" className="close" onClick={props.onToggleMenu} href="#">Close</a>
                    </nav>
                )
            }
            />
    )
}

Menu.propTypes = {
  onToggleMenu: PropTypes.func
}

export default Menu
