import React from "react";
import PropTypes from "prop-types";
import Logo from "../assets/images/logo.png";
import { graphql, Link, StaticQuery } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import utils  from "../helpers/utils";

const query = graphql`query HeaderItemsQuery {
    allStrapiHeaderItems (sort: {fields: order}) {
        edges {
            node {
                name
                locale
                order
                path
                icon
                is_child
                is_special
                header_items {
                    name
                    order
                    path
                    icon
                    is_child
                    enabled
                    is_special
                    background {
                        color
                        images {
                            url
                            alternativeText
                            formats {
                                xsmall {
                                    url
                                }
                                small {
                                    url
                                }
                                medium {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`;

const ExactNavLink = (props) => {

    if(props.children !== undefined && props.children.length > 0) {
      return (
          <>
            <div className="dropdown">
                <Link className="dropbtn mx-1" to={props.path}>{props.name} <FontAwesomeIcon icon={faChevronDown} size="xs"/></Link>
                <div className="dropdown-content">
                    <section className="tiles w-100">
                    {
                        props.children.sort((a, b) => { return a.order - b.order }).map((child, index) => (
                            <article 
                                key={index} 
                                className={`bg-${child.background?.color}`} 
                                style={{backgroundImage: `url(${utils.image(child.background.images[0], "xsmall")})`, width: `${props.children.length > 1 ? 100 / props.children.length : 50}%`}}>
                                <header className="major">
                                    <p>{child.name}</p>
                                </header>
                                <Link to={child.path} className="link primary position-absolute h-100"></Link>
                            </article>
                        ))
                    }
                    </section>
                </div>
            </div>
          </>
      )
    }
    else {
      return (
        <Link className="mx-3" to={props.path}>{props.name}</Link>
      )
    }
}

const LanguageDropdown = (props) => {

    const [isVisible, setIsVisible] = React.useState(false);
    const [icon, setIcon] = React.useState(faChevronDown);

    const onToggleLanguage = () => {
        setIsVisible(!isVisible);
        setIcon(icon === faChevronDown ? faChevronUp : faChevronDown);
    }

    const onCloseLanguage = (event) => {
        console.log(event);
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setIsVisible(false);
            setIcon(faChevronDown);
        }
    }
    
    const locale = props.locale;
    const languages = props.languages;
    let uniqueLanguages = [...new Set(languages)];

    return(
        <div className="d-flex justify-content-end">
            <div className="dropdown ms-4" role="button" onBlur={onCloseLanguage}>
                <a className="btn p-0 pb-1 pe-2" role="button" tabIndex={0} id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{color: '#777', fontWeight: 'bold'}} onClick={onToggleLanguage} onKeyDown={onToggleLanguage}>
                    <CurrentLanguage locale={locale} icon={icon}/>
                </a>
                <ul className="dropdown-menu border-1 bg-white" aria-labelledby="dropdownMenuLink" style={{display: isVisible ? 'block' : 'none', maxWidth: '6em', minWidth: '6em', marginLeft: '-1em'}}>
                    { 
                        uniqueLanguages.filter(language => language !== locale).map(language => (
                            <li key={language} className="p-0 ps-1">
                                <LanguageOption language={language} />
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

const CurrentLanguage= (props) => {
    
    const locale = props.locale;
    const icon = props.icon;
    
    var current = utils.currentLanguage(locale);

    return (
    <>
        <FontAwesomeIcon className="d-inline-block me-2 mt-1" icon={faGlobe} size="xs"/>
        <span style={{verticalAlign: 'middle'}}>{current.span} <FontAwesomeIcon icon={icon} size="xs"/></span>
    </>)
}

const LanguageOption = (props) => {

    const language = props.language;
    var option = utils.currentLanguage(language);
    var baseUrlLang =  `/${language !== 'fr' ? language : ''}`;

    return (
        <Link to={baseUrlLang} className="dropdown-item language-item p-0 ps-3" style={{fontSize: "1em", color: '#555 !important', fontWeight: 'bold', verticalAlign: 'sub'}}>
            <FontAwesomeIcon className="d-inline-block me-2 mt-1" icon={faGlobeEurope} size="xs"/>
            <span>{option.span}</span>
        </Link>)
}

const Header = (props) => {
    
    const locale = props.locale;
    var baseUrlLang =  `/${locale !== 'fr' ? locale : ''}`;
    
    return(
        <StaticQuery query={query} render={ data => (
            <header id="header">
                <Link to={baseUrlLang} className="logo"><img className="d-inline title" src={Logo} alt="Idunn logo" style={{height: "4.5em"}}/></Link>
                    <nav className="d-none d-xl-flex justify-content-start ms-5">
                    {
                        data.allStrapiHeaderItems?.edges?.filter(x => !x.node.is_child && !x.node.is_special && x.node.locale === locale).map((item, index) => (
                        <ExactNavLink key={index} name={item.node.name} path={item.node.path} children={item.node.header_items} onToggleMenu={props.onToggleMenu} /> ))
                    }
                    {
                        data.allStrapiHeaderItems?.edges?.filter(x => !x.node.is_child && x.node.is_special && x.node.locale === locale).map((item, index) => (
                        <div key={index}> 
                            <Link className="button special-menu fit px-4 ms-5" to={item.node.path}>{item.node.name}</Link>
                        </div> ))
                    }
                    </nav>
                    <nav className="d-xl-none">
                        <div>
                            <button className="menu-link text-black shadow-none" onClick={props.onToggleMenu}>Menu</button>
                        </div> 
                    </nav>
                    <LanguageDropdown locale={locale} languages={data.allStrapiHeaderItems?.edges.map(x => x.node.locale)}/> 
            </header>
        ) } />
    )
}

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
